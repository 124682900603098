<template>
    <!--  br 标签给页脚留出位置  -->
    <div id="footer">
        <p style="font-size:10px;">服务热线:010-85835562,010-69519882</p>
        <p style="font-size:10px;">Copyright © 2023 Fost All Rights Reserved. Fost 版权所有</p>
        <p style="font-size:10px;"><a href="http://beian.miit.gov.cn/">京ICP备2021007003号</a></p>
    </div>
</template>

<script>
export default {
    name: 'FostFooter'
}
</script>

<style scoped>
#footer {
    left: 0;
    bottom: 0;
    height: 60px;
    width: 100%;
    text-align: center;
}
</style>