export default function formattedTime(iso_date_string) {
    let date = new Date(iso_date_string);
    let ds = date.toLocaleDateString().replace(/\//g, '-').split('-')
    let y = ds[0]
    let m = ds[1]
    let d = ds[2]
    if (m.length == 1) {
        m = '0' + m
    }
    if (d.length == 1) {
        d = '0' + d
    }
    return y + '-' + m + '-' + d
}