<!--  frontend/src/views/Login.vue  -->
<template>
  <el-dialog
    title="登录FOST研究报告数据库"
    width="480px"
    v-model="loginVisible"
  >
    <div>
      <el-tabs
        v-model="activeName"
        @tab-change="onLoginChange"
      >
        <el-tab-pane
          label="账号密码"
          name="account"
        ><el-row>
            <el-col :span="24">
              <el-form
                :model="form"
                label-width="60px"
                style="max-width: 300px"
              >
                <el-form-item label="用户名">
                  <el-input
                    v-model="form.username"
                    @keyup.enter="onLogin"
                  />
                </el-form-item>
                <el-form-item label="密码">
                  <el-input
                    v-model="form.pwd"
                    type="password"
                    placeholder="请输入密码"
                    show-password
                    @keyup.enter="onLogin"
                  />
                </el-form-item>
                <el-form-item label="验证码">
                  <el-row :gutter="10">
                    <el-col
                      :span="12"
                      @click="captcha_get"
                    >
                      <img
                        :src="imgurl"
                        alt=""
                      >
                    </el-col>
                    <el-col :span="12"><el-input
                        v-model="form.captcha"
                        type="text"
                        placeholder="点击图片刷新"
                      />
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item>
                  <div style="text-align: right; ">
                    <el-button
                      type="primary"
                      @click="onLogin"
                    >登录</el-button>
                  </div>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row></el-tab-pane>
        <el-tab-pane
          label="手机号码"
          name="phone"
        ><el-row>
            <el-col :span="24">
              <el-form
                :model="form"
                style="max-width: 300px"
              >
                <el-form-item label="手机号">
                  <el-input
                    v-model="form.username"
                    @keyup.enter="onLogin"
                    placeholder="请输入手机号"
                  />
                </el-form-item>
                <el-form-item label="验证码" v-if="isShowCode">
                  <el-input
                    v-model="form.pwd"
                    type="text"
                    placeholder="请输入手机验证码"
                  />
                </el-form-item>
                <el-form-item v-if="!isShowCode">
                  <el-row :gutter="10">
                    <el-col
                      :span="12"
                      @click="captcha_get"
                    >
                      <img
                        :src="imgurl"
                        alt=""
                      >
                    </el-col>
                    <el-col :span="8"><el-input
                        v-model="form.captcha"
                        type="text"
                        placeholder="点击图片刷新"
                      />
                    </el-col>
                    <el-col :span="4"><el-button
                        type="primary"
                        @click="captcha_verify()"
                      >验证</el-button>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item v-if="isShowCode">
                  <div style="text-align: right; ">
                    <el-button
                      type="primary"
                      @click="onLogin"
                    >登录</el-button>
                  </div>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row></el-tab-pane>
        <el-tab-pane
          label="邮箱账号"
          name="mail"
        ><el-row>
            <el-col :span="24">
              <el-form
                :model="form"
                style="max-width: 300px"
              >
                <el-form-item label="邮件地址">
                  <el-input
                    v-model="form.username"
                    @keyup.enter="onLogin"
                    placeholder="请输入邮件地址"
                  />
                </el-form-item>
                <el-form-item label="验证码" v-if="isShowCode">
                  <el-input
                    v-model="form.pwd"
                    type="text"
                    placeholder="请输入邮箱验证码"
                  />
                </el-form-item>
                <el-form-item v-if="!isShowCode">
                  <el-row :gutter="10">
                    <el-col
                      :span="12"
                      @click="captcha_get"
                    >
                      <img
                        :src="imgurl"
                        alt=""
                      >
                    </el-col>
                    <el-col :span="8"><el-input
                        v-model="form.captcha"
                        type="text"
                        placeholder="点击图片刷新"
                      />
                    </el-col>
                    <el-col :span="4"><el-button
                        type="primary"
                        @click="captcha_verify()"
                      >验证</el-button>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item v-if="isShowCode">
                  <div style="text-align: right; ">
                    <el-button
                      type="primary"
                      @click="onLogin"
                    >登录</el-button>
                  </div>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row></el-tab-pane>
      </el-tabs>

      <el-row>
        <el-col :span="24">
          <el-divider />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <p>
            没有开通账号，或账号登录有问题，请联系FOST客服。
          </p>
          <p>
            电话：010-69519882，85835562，(工作日8:00-17:00)
          </p>
          <p>
            电子邮箱：info@fostecon.cn
          </p>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
  <el-dialog
    title="请输入验证码"
    width="320px"
    v-model="captchaVisible"
  >
    <el-row :gutter="10">
      <el-col
        :span="12"
        @click="captcha_get"
      >
        <img
          :src="imgurl"
          alt=""
        >
      </el-col>
      <el-col :span="12"><el-input
          v-model="form.captcha"
          type="text"
          placeholder=""
        />
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col
        :span="24"
        style="text-align:right"
      >
        <el-button
          type="primary"
          @click="captcha_verify()"
        >验证</el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>
<script>
import { reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import qs from "qs";
import { ElMessage } from "element-plus";
const lg_enough = ref(true);
const isShowCode = ref(false)
const imgurl = ref("");
const form = reactive({
  username: "",
  pwd: "",
  captcha: "",
  uuid: "",
  type: "account",
});
const captchaVisible = ref(false);
const codeSended = ref(false);
const activeName = ref("account");
const loginVisible = ref(true);
let redirect_id = 0;

export default {
  mounted() {
    lg_enough.value = document.body.clientWidth >= 992;
    if (window.location.search != "") {
      let parmas = window.location.search.split("/");
      redirect_id = parseInt(parmas[parmas.length - 1]);
    }
  },
  setup() {
    let route = useRoute();
    let router = useRouter();
    if (route.params.msg !== undefined && route.params.msg.length > 0) {
      ElMessage.error(route.params.msg);
    }
    // 手机号获取验证码前先输入图形验证码
    const onGetMSg = () => {
      var phone_reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      var mail_reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (form.type == "phone" && !phone_reg.test(form.username)) {
        ElMessage.error("请输入正确的手机号");
      } else if (form.type == "mail" && !mail_reg.test(form.username)) {
        ElMessage.error("请输入正确的邮箱");
      } else {
        captcha_get();
        form.captcha = "";
        captchaVisible.value = true;
      }
    };
    // 切换登录类型
    const onLoginChange = () => {
      form.username = "";
      form.pwd = "";
      form.captcha = "";
      form.type = activeName.value;
      captcha_get();
    };
    const captcha_verify = async () => {
      let url =
        "/api/captcha_verify?uuid=" +
        form.uuid +
        "&captcha=" +
        form.captcha +
        "&uname=" +
        form.username +
        "&utype=" +
        form.type;
      let response = await axios.get(url);
      if (response.data.result) {
        if (form.type == "phone") {
          ElMessage.success("短信验证码已发送");
        }
        if (form.type == "mail") {
          ElMessage.success("验证码已发送至您的邮箱");
        }
        codeSended.value = true;
        captchaVisible.value = false;
        isShowCode.value = true
      } else {
        ElMessage.error(response.data.msg);
      }
    };
    // 获取图形验证码
    const captcha_get = async () => {
      let s = [];
      let hexDigits = "0123456789abcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4";
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
      s[8] = s[13] = s[18] = s[23] = "-";

      form.uuid = s.join("");
      let url = "/api/captcha_get?uuid=" + form.uuid;
      let response = await axios.get(url);
      imgurl.value = response.data.base64;
    };
    const getToken = async () => {
      let url = "/api/token";
      let response = await axios.get(url);
      return response.data.token;
    };
    // 登录
    const onLogin = async () => {
      if (form.username === "" || form.pwd === "") {
        if (form.type == "account") {
          ElMessage.error("请输入用户名和密码");
          return;
        }
        if (form.type == "phone") {
          ElMessage.error("请输入手机号及验证码");
          return;
        }
        if (form.type == "mail") {
          ElMessage.error("请输入邮箱及验证码");
          return;
        }
      }
      if (form.type == "account" && form.captcha === "") {
        ElMessage.error("请输入验证码");
        return;
      }
      let url = "/api/login";
      let csrftoken = getToken();
      let headers = { "X-CSRFToken": csrftoken };
      let response = await axios.post(url, qs.stringify(form), {
        headers: headers,
      });
      let result = response.data.result;
      codeSended.value = false;
      isShowCode.value = false;
      if (result) {
        localStorage.setItem("Authorization", true);
        localStorage.setItem("company", response.data.msg);
        localStorage.setItem("info", response.data.info);
        let route = router.resolve({ path: "/home" });
        if (redirect_id != 0) {
          route = router.resolve({ path: "/article/" + redirect_id });
        }
        window.open(route.href, "_self");
      } else {
        ElMessage.error(response.data.msg);
      }
    };
    captcha_get();
    return {
      captcha_get,
      form,
      lg_enough,
      activeName,
      imgurl,
      onLogin,
      captcha_verify,
      onGetMSg,
      onLoginChange,
      loginVisible,
      isShowCode
    };
  },
};
// do not use same name with ref
</script>
<style>
.card-header {
  display: flex;
  align-items: baseline;
}
.card-header-center {
  display: flex;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 400px;
}
.lg_css {
  display: flex;
  justify-content: center;
  padding: 200px;
}
.sm_css {
  display: flex;
  justify-content: center;
  padding: 50px;
}
</style>
 
