<!--  frontend/src/views/Home.vue  -->
<template>
  <el-container>
    <el-header style="height:150px;padding:0">
      <IndexHeader />
    </el-header>
    <el-container height="600px">
      <el-aside
        v-if="mid == '0'"
        width="200px"
        v-show="lg_enough"
      >
        <TreeMenu />
      </el-aside>
      <el-aside
        v-else
        width="200px"
        v-show="lg_enough"
      >
        <el-row style="margin-left:10px;margin-top:30px">
          <svg
            style="
                        width: 3em;
                        height: 3em;
                        margin-right: 2px;
                        margin-left: 2px;
                        margin-bottom: 4px;
                        color: red;
                      "
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            data-v-78e17ca8=""
          >
            <path
              fill="currentColor"
              d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296-44.096 0-108.992 44.736-148.48 101.504 0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04 67.84 0 107.904-43.648 147.456-100.416z"
            ></path>
          </svg>
          <span style="font-size:30px;margin-top:2px;color: #222222">
            {{report_name}}
          </span>
        </el-row>
        <el-row style="margin-left:10px;margin-top:30px;line-height:30px;height:400px">
          <span style="font-size: small; color: #666666">
            {{report_desc}}
          </span>
        </el-row>
        <el-row
          :span="24"
          style="margin-left:10px;margin-top:30px"
        >
          <span style="font-size: small; color: #999999;">
            {{poetry_en}}
          </span>
        </el-row>
        <el-row
          :span="24"
          style="margin-left:10px;margin-top:30px"
        >
          <span style="font-size: small; color: #999999">
            {{poetry_cn}}
          </span>
        </el-row>

      </el-aside>
      <el-container>
        <el-main style="padding-left: 10px; padding-top: 0; padding-right: 10px">
          <ArticleList />
        </el-main>
      </el-container>
      <el-aside
        width="300px"
        style="margin-top: 20px;"
        v-show="lg_enough"
      >
        <RightSide />
      </el-aside>
    </el-container>
    <el-footer style="padding: 0px">
      <Footer />
    </el-footer>
  </el-container>
</template>

<script>
import IndexHeader from "@/components/IndexHeader.vue";
import Footer from "@/components/Footer.vue";
import ArticleList from "@/components/ArticleList.vue";
import TreeMenu from "@/components/TreeMenu.vue";
import RightSide from "@/components/RightSide.vue";
import { emitter } from "@/libs/bus.ts";
import { ref } from "vue";
import axios from "axios";
const mid = ref("0");
const poetry_cn = ref("");
const poetry_en = ref("");
const report_name = ref("");
const report_desc = ref("");
const lg_enough = ref(true);
const rebuild = (msg) => {
  mid.value = msg.text;
  if (mid.value !== "0") {
    getInfo();
  }
};
const getInfo = async () => {
  var url = "/api/report_info/" + mid.value;
  var response = await axios.get(url);
  poetry_cn.value = response.data[0]["poetry_cn"];
  poetry_en.value = response.data[0]["poetry_en"];
  report_name.value = response.data[0]["source"];
  report_desc.value = response.data[0]["desc"];
};
emitter.on("switch", rebuild);
export default {
  mounted() {
    lg_enough.value = document.body.clientWidth >= 992;
  },
  name: "FostHome",
  components: { IndexHeader, Footer, ArticleList, TreeMenu, RightSide },
  data() {
    return {
      mid,
      poetry_cn,
      poetry_en,
      report_name,
      report_desc,
      lg_enough,
    };
  },
};
</script>