<template>
  <div></div>
</template>
<script>
import axios from "axios";
import { useRouter } from "vue-router";
export default {
  setup() {
    let router = useRouter();
    const getToken = async () => {
      let url = "/api/token/";
      let response = await axios.get(url);
      LoginByIp(response.data.token);
    };
    const LoginByIp = async (token) => {
      // console.log("LoginByIp");
      document.cookie = "csrftoken=" + token;
      let url = "api/login_by_ip/";
      let headers = { "X-CSRFToken": token };
      const response = await axios.post(url, {
        headers: headers,
      });
      if (response.data.result) {
        localStorage.setItem("Authorization", true);
        localStorage.setItem("company", response.data.msg);
        localStorage.setItem("info", response.data.info);
      } else {
        localStorage.setItem("Authorization", false);
      }
      router.push("/home");
    };
    getToken();
  },
};
// do not use same name with ref
</script>
