<template>
  <el-row
    style="padding: 0px; margin-bottom: 0px; margin-top: 0px"
    justify="space-between"
  >
    <el-col
      :xs="6"
      :sm="6"
      :md="12"
      :lg="12"
      :xl="12"
      style="padding: 0px; margin-bottom: 0px; margin-top: 15px"
    >
      <div
        class="card-header-center"
        style="padding-left:10px"
      >
        <div class="card-header">
          <span style="font-size: 40px; color: red; font-weight: bold; ">F</span><span style="font-size: 35px; color: red; font-weight: bold; padding-right:5px ">OST</span>
        </div>
        <span
          v-if="lg_enough"
          style="font-size: 24px;padding-right: 10px;"
        >研究报告数据库</span>

      </div>
    </el-col>
    <el-col
      :xs="16"
      :sm="16"
      :md="8"
      :lg="8"
      :xl="8"
    >
      <el-row
        style="margin-right:15px"
        v-if="logined&&lg_enough"
      >
        <el-col style="text-align:right;"><el-button
            type="primary"
            :icon="User"
            size="small"
            circle
            @click="UserInfoVisble=true"
          />
          <a
            href="/logout"
            style="margin:10px"
          >[登出]</a></el-col>
      </el-row>
      <el-row
        v-if="!lg_enough"
        style="margin-top: 25px;margin-right: 25px;"
        justify="end"
      >
        <el-col style="text-align:right">
          <el-button
            @click="SearchVisble=true"
            type="primary"
            round
          >
            打开搜索
          </el-button>
        </el-col>
      </el-row>
      <el-row
        style="margin-top: 5px;"
        justify="end"
        v-if="lg_enough"
      >
        <el-col :span="4">
          <el-select
            v-model="ss_value"
            style="width:100px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>
        <el-col :span="10">
          <el-input
            style="width:250px"
            type="text"
            placeholder="搜索"
            v-model="search_input"
            :prefix-icon="Search"
            @keyup.enter="search"
          >
          </el-input>
        </el-col>
        <el-col :span="3">
          <el-button @click="search()">
            搜索
          </el-button>
        </el-col>
      </el-row>
      <el-row
        v-show="lg_enough"
        style="margin-top: 10px;margin-right: 25px;"
        justify="end"
      >
        <el-col style="text-align:right"><el-date-picker
            v-model="dataRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始"
            end-placeholder="结束"
            unlink-panels
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            @change="dataPanelChange"
            style="width:390px"
          /></el-col>
      </el-row>
    </el-col>
  </el-row>
  <el-row>
    <el-col>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item
          v-for="item of menu"
          :key="item.id"
          :index="String(item.id)"
        >{{item.source}}</el-menu-item>
      </el-menu>
    </el-col>
  </el-row>
  <el-dialog
    v-model="UserInfoVisble"
    title="用户中心"
    width="30%"
  >
    <span>用户名称:{{nick_name}}</span>
    <el-table
      :data="info"
      style="width: 100%"
    >
      <el-table-column
        prop="name"
        label="产品"
      />
      <el-table-column
        prop="status"
        label="状态"
      />
      <el-table-column
        prop="start"
        label="报告浏览开始日期"
        width="180"
      />
      <el-table-column
        prop="end"
        label="报告浏览截止日期"
        width="180"
      />
    </el-table>
    <p>
      没有购买报告及报告服务问题，请联系FOST客服。
    </p>
    <p>
      电话：010-69519882，85835562，(工作日8:00-17:00)
    </p>
    <p>
      电子邮箱：info@fostecon.cn
    </p>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="UserInfoVisble = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    v-model="SearchVisble"
    title="搜索"
    width="90%"
  >
    <el-row
      style="margin-top: 5px;"
      gutter="5"
    >
      <el-col :span="6">
        <el-select v-model="ss_value">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-col>
      <el-col :span="17">
        <el-input
          type="text"
          placeholder="搜索"
          v-model="search_input"
          :prefix-icon="Search"
          @keyup.enter="search"
        >
        </el-input>
      </el-col>
    </el-row>
    <el-row style="margin-top: 10px;">
      <el-col><el-date-picker
          v-model="dataRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始"
          end-placeholder="结束"
          unlink-panels
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          @change="dataPanelChange"
          style="width:90%"
        /></el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="SearchVisble = false">关闭</el-button>
        <el-button
          type="primary"
          @click=search()
        >
          搜索
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { ref, onUnmounted } from "vue";
import { emitter } from "@/libs/bus.ts";
import axios from "axios";
import { ElMessage } from "element-plus";
import { Search, User } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
const lg_enough = ref(true);
const logined = ref(false);
const nick_name = ref("");
const UserInfoVisble = ref(false);
const SearchVisble = ref(false);
const info = ref([]);
export default {
  setup() {
    return {
      Search,
      User,
    };
  },
  mounted() {
    lg_enough.value = document.body.clientWidth >= 992;
    logined.value =
      localStorage.getItem("Authorization") == "true" ? true : false;
    if (localStorage.getItem("info")) {
      info.value = JSON.parse(localStorage.getItem("info")).products;
    }
    if (logined.value) {
      nick_name.value = localStorage.getItem("company");
    } else {
      nick_name.value = "";
    }
  },
  data() {
    const ss_value = ref("0");
    const options = [
      {
        value: "0",
        label: "标题",
      },
      {
        value: "1",
        label: "全文",
      },
    ];
    const menu = ref([
      { id: "0", source: "" },
      { id: "1", source: "" },
      { id: "2", source: "" },
      { id: "3", source: "" },
      { id: "4", source: "" },
      { id: "5", source: "" },
      { id: "6", source: "" },
    ]);
    const dataRange = ref([]);
    const dataPanelChange = (val) => {
      emitter.emit("date-change", { date_range: val });
    };
    const search_input = ref("");
    const activeIndex = ref("0");
    let url = "api/menu/";
    let router = useRouter();
    const getMenu = async () => {
      try {
        var response = await axios.get(url);
        response.data.splice(0, 0, { id: "0", source: "全部" });
        menu.value = response.data;
      } catch (err) {
        if (err.toJSON().status == 403) {
          router.push({ path: "/login" });
          return;
        }
      }
    };
    getMenu();
    const search = () => {
      if (search_input.value != "") {
        activeIndex.value = "0";
        SearchVisble.value = false;
        emitter.emit("search", {
          text: search_input.value,
          date: dataRange.value,
          t: ss_value.value,
        });
      } else {
        ElMessage({
          message: "请输入关键字。",
          type: "error",
        });
      }
    };
    const handleSelect = (key) => {
      dataRange.value = "";
      search_input.value = "";
      emitter.emit("switch", { text: key, disabled: "0" });
    };
    const date_clear = () => {
      dataRange.value = "";
    };
    const search_clear = () => {
      search_input.value = "";
    };
    emitter.on("date-clear", date_clear);
    emitter.on("search-clear", search_clear);
    onUnmounted(() => {
      emitter.off("date-clear", date_clear);
      emitter.off("search-clear", search_clear);
    });
    return {
      search_input,
      handleSelect,
      activeIndex,
      UserInfoVisble,
      search,
      menu,
      dataRange,
      dataPanelChange,
      lg_enough,
      options,
      ss_value,
      logined,
      nick_name,
      info,
      SearchVisble,
    };
  },
};
</script>


<style scoped>
.el-menu-item {
  font-size: 16px;
}
.el-row {
  margin-bottom: 0px;
  height: 50;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.lg_css {
  padding: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.sm_css {
  padding: 0px;
  margin-bottom: 10px;
  margin-top: 25px;
}
.card-header {
  display: flex;
  align-items: baseline;
}
.card-header-center {
  display: flex;
  align-items: center;
}
</style>