<!--  frontend/src/components/RightSide.vue  -->
<template>
  <div>
    <!-- <div v-if="mid !=='0'">
      <div>
        <el-row
          justify="center"
          style="margin-bottom: 14px; margin-top: 14px; width: 99%"
        >本版热门</el-row>
      </div>
      <el-divider style="margin-bottom: 14px; margin-top: 14px; width: 99%" />
      <div
        v-for="(item, id) in top_select"
        :key="id"
      >
        <el-row>
          <el-col>

            <router-link
              target="_blank"
              :to="{ name: 'ArticleDetail', params: { id: item.id } }"
              style="text-decoration: none"
            >
              <el-link
                :underline="false"
                class="el-link-list"
              >
                <span style="color: brown">ㆍ</span>
                <span>{{ item.title }}</span>
              </el-link>
            </router-link>
          </el-col>
        </el-row>
      </div>
    </div> -->
    <div>
      <div>
        <el-row
          justify="center"
          style="margin-bottom: 14px; margin-top: 14px; width: 99%"
        >热门报告</el-row>
      </div>
      <el-divider style="margin-bottom: 14px; margin-top: 14px; width: 99%" />
      <div
        v-for="(item, id) in top_all"
        :key="id"
      >
        <el-row>
          <el-col>
            <router-link
              target="_blank"
              :to="{ name: 'ArticleDetail', params: { id: item.id } }"
              style="text-decoration: none"
            >
              <el-link
                :underline="false"
                class="el-link-list"
              >
                <span style="color: brown">ㆍ</span>
                <span>{{ item.title }}</span>
              </el-link>
            </router-link>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { emitter } from "@/libs/bus.ts";
const value = ref(new Date());
export default {
  setup() {
    const top_all = ref([]);
    const top_select = ref([]);
    const mid = ref("0");
    let router = useRouter();

    const getTopList = async (val) => {
      var url = "/api/top/" + val;
      try {
        var response = await axios.get(url);
        if (val == "0") {
          top_all.value = response.data;
        } else {
          top_select.value = response.data;
        }
      } catch (err) {
        if (err.toJSON().status == 403) {
          router.push({ path: "/login" });
          return;
        }
      }
    };
    const getListByMenu = (msg) => {
      mid.value = msg.text;
      getTopList(mid.value);
    };
    getTopList("0");
    emitter.on("switch", getListByMenu);
    onUnmounted(() => {
      emitter.off("switch", getListByMenu);
    });
    return {
      value,
      top_all,
      top_select,
      mid,
    };
  },
};
</script>
<style scoped>
.el-link-list {
  font-size: 14px;
  color: #666666;
  font-weight: 500;
  font-family: "微软雅黑", "宋体";
}
.el-row {
  margin-bottom: 10px;
}
</style>