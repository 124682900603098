<!--  frontend/src/views/ArticleDetail.vue  -->
<template class="body">
  <div
    v-if="pdfUrl !== ''"
    class="body"
  >
    <iframe
      v-if="pdf_enabled"
      :src="pdfUrl"
      frameborder="0"
      scrolling="no"
      allowfullscreen="true"
      allowtransparency="true"
      class="iframe"
    >
    </iframe>
    <!-- <div v-else>
      您的浏览器不支持PDF预览,请下载阅读:<span id="download_pdf"></span>
    </div> -->
  </div>
  <div v-else>
    <el-row justify="center">
      <el-col :span="24">
        <Header />
      </el-col>
      <el-col :span="22">
        <div v-if="article !== null">
          <h1 id="title">{{ article.title }}</h1>
          <p class="subtitle">
            发布于 {{ formatted_time(article.publish_date) }}
            <router-link
              v-if="article.parent !== -1"
              target="_blank"
              :to="{ name: 'ArticleDetail', params: { id: article.parent } }"
              style="text-decoration: none"
            >查看当期报告
              <svg
                style="
                  width: 1em;
                  height: 1em;
                  margin-right: 1px;
                  margin-left: 1px;
                  margin-bottom: -2px;
                "
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                data-v-ba633cb8=""
              >
                <path
                  fill="currentColor"
                  d="M832 384H576V128H192v768h640V384zm-26.496-64L640 154.496V320h165.504zM160 64h480l256 256v608a32 32 0 0 1-32 32H160a32 32 0 0 1-32-32V96a32 32 0 0 1 32-32zm160 448h384v64H320v-64zm0-192h160v64H320v-64zm0 384h384v64H320v-64z"
                ></path>
              </svg>
            </router-link>
          </p>
        </div>
      </el-col>
      <el-col
        :xs="22"
        :sm="22"
        :md="20"
        :lg="20"
        :xl="18"
      >
        <div v-if="article !== null">
          <div
            v-html="article.body"
            style="
              text-indent: 2em;
              font-family: 宋体;
              font-size: 14pt;
              line-height: 22pt;
            "
          ></div>
        </div>
      </el-col>
      <el-col>
        <Footer />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Header from "@/components/DetailHeader.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import formattedTime from "@/composables/formattedTime.js";
import { useRouter, useRoute } from "vue-router";
import { ref } from "vue";
import { ElLoading } from "element-plus";
export default {
  components: { Header, Footer },
  computed: {
    // 滚动区高度
    // (业务需求：手机屏幕高度减去头部标题和底部tabbar的高度，当然这2个高度也是可以动态获取的)
    scrollerHeight: function () {
      return window.innerHeight - 35 + "px";
    },
  },
  created() {
    // Mozilla/5.0 (iPhone; CPU iPhone OS 14_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.1 Mobile/15E148 Safari/604.1
    // var str = navigator.userAgent.toLowerCase();
  },
  setup() {
    const pdf_enabled = ref(true);
    const article = ref(null);
    const pdfUrl = ref("");
    const pdf_blob_url = ref("");
    let route = useRoute();
    let router = useRouter();
    const formatted_time = formattedTime;
    const check_version = () => {
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 14_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.1 Mobile/15E148 Safari/604.1"
      var str = navigator.userAgent.toLowerCase();
      var ver = str.match(/cpu iphone os (.*?) like mac os/);
      if (ver) {
        var ios_ver = ver[1].replace(/_/g, ".").split(".");
        if (Number(ios_ver[0]) < 15) {
          pdf_enabled.value = false;
        }
      }
    };
    // const ab2str = (buf) => {
    //   return JSON.parse(
    //     decodeURIComponent(
    //       escape(String.fromCharCode.apply(null, new Uint8Array(buf)))
    //     )
    //   );
    // };
    const Uint8ArrayToString = (array) => {
      var out, i, len, c;
      var char2, char3;

      out = "";
      len = array.length;
      i = 0;
      while (i < len) {
        c = array[i++];
        switch (c >> 4) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
            // 0xxxxxxx
            out += String.fromCharCode(c);
            break;
          case 12:
          case 13:
            // 110x xxxx   10xx xxxx
            char2 = array[i++];
            out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
            break;
          case 14:
            // 1110 xxxx  10xx xxxx  10xx xxxx
            char2 = array[i++];
            char3 = array[i++];
            out += String.fromCharCode(
              ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
            );
            break;
        }
      }

      return out;
    };
    const getData = async () => {
      let loading = ElLoading.service({ fullscreen: true });
      let Base64 = require("js-base64").Base64;
      try {
        let response = await axios.get("/api/article/" + route.params.id, {
          responseType: "arraybuffer",
        });
        var contentType = response.headers["content-type"];
        if (contentType == "application/octet-stream") {
          const binaryData = [];
          binaryData.push(response.data);
          pdf_blob_url.value = window.URL.createObjectURL(
            new Blob(binaryData, { type: "application/pdf;charset=UTF-8" })
          );
          pdfUrl.value =
            "/lib/web/viewer.html?file=" +
            encodeURIComponent(pdf_blob_url.value);
          let link = document.createElement("a");
          let filename = response.headers["content-disposition"]
            .replace("=?utf-8?b?", "")
            .replace("=?=", "")
            .replace("?=", "");
          filename = Base64.decode(decodeURIComponent(filename));
          filename = filename.replace('attachment;filename="', "");
          filename = filename.replace('"', "");
          link.href = pdf_blob_url.value;
          link.setAttribute("download", filename);
          link.textContent = "您的浏览器不支持PDF预览,请下载阅读";
          document.body.appendChild(link);
          // document.getElementById("download_pdf").appendChild(link)
          // window.URL.revokeObjectURL(link.href);
        } else {
          // article.value = ab2str(response.data)
          article.value = JSON.parse(
            Uint8ArrayToString(new Uint8Array(response.data))
          );
        }
        loading.close();
      } catch (err) {
        console.log(err);
        loading.close();
        if (err.toJSON().status == 403) {
          router.push({
            path: "/login",
            query: { next: "/article/" + route.params.id.toString() },
          });
        }
      }
    };
    check_version();
    getData();
    return {
      article,
      formatted_time,
      pdfUrl,
      pdf_enabled,
      pdf_blob_url,
    };
  },
};
</script>

<style>
#app {
  margin: 0px; /* Reset default margin */
  margin-left: 0px; /* Reset default margin */
  margin-right: 0px; /* Reset default margin */
  margin-top: 0px; /* Reset default margin */
  margin-bottom: 0px; /* Reset default margin */
  padding: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
}
body {
  margin: 0px; /* Reset default margin */
  margin-left: 0px; /* Reset default margin */
  margin-right: 0px; /* Reset default margin */
  margin-top: 0px; /* Reset default margin */
  margin-bottom: 0px; /* Reset default margin */
  padding: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
}
.iframe {
  display: block; /* iframes are inline by default */
  background: #000;
  border: none; /* Reset default border */
  height: 100vh; /* Viewport-relative units */
  width: 100%;
}
.grid-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

#title {
  text-align: center;
  font-size: x-large;
}

.subtitle {
  text-align: center;
  color: gray;
}

.toc ul {
  list-style-type: none;
}

.toc a {
  color: gray;
}
</style>