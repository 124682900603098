<template>
  <div>
    <el-row
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="18"
    >
      <el-col style="text-align:center">
      <div>
        <span style="
            margin-left: 10px;
            font-family: Castellar;
            font-size: 40px;
            color: red;
            font-weight: bold;
          ">F</span>
        <span style="
            margin-right: 10px;
            font-family: Castellar;
            font-size: 34px;
            color: red;
            font-weight: bold;
          ">ost</span>
        <span style="font-size: 24px">研究报告数据库</span>
      </div>
      </el-col>
    </el-row>
    <el-divider />
  </div>
</template>
