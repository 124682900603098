<!--   frontend/src/App.vue   -->
<template>
  <router-view />
</template>

<script>
    export default {
        name: 'App',
    }
</script>

<style>
#app {
  font-family: "微软雅黑", "宋体";
  margin-left: 40px;
  margin-right: 40px;
}
</style>