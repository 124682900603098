<template>
  <div></div>
</template>
  <script>
import axios from "axios";
import { useRouter } from "vue-router";
export default {
  setup() {
    let router = useRouter();
    const logout = async () => {
      let url = "api/logout";
      const response = await axios.get(url);
      if (response) {
        localStorage.setItem("Authorization", false);
        localStorage.setItem("company", "");
        localStorage.setItem("info", "");
        router.push("/");
      }
    };
    logout();
  },
};
// do not use same name with ref
</script>
  