// frontend/src/router/index.js
import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import LogoutSys from "@/views/LogoutSys.vue";
import LoginByIp from "@/views/LoginByIp.vue";
import Login from "@/views/LoginVue.vue";
import ArticleDetail from "@/views/ArticleDetail.vue";


const routes = [

  {
    path: "/logout",
    name: "logout",
    component: LogoutSys,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/",
    name: "lbi",
    component: LoginByIp,
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      title: "首页",
    },
  },
  {
    path: "/article/:id",
    name: "ArticleDetail",
    component: ArticleDetail,
    meta: {
      title: "报告详情",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from) => {
//   let isAuthenticated = localStorage.getItem('Authorization');
//   console.log("FROM:" + from.fullPath);
//   console.log("From NAME:" + from.name);
//   console.log("TO:" + to.fullPath);
//   console.log("TO NAME:" + to.name);
//   console.log("isAuthenticated:" + isAuthenticated);
// });

export default router;
