import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import axios from 'axios'
URLSearchParams.prototype.appendIfExists = function (key, value) {
  if (value !== null && value !== undefined) {
    this.append(key, value)
  }
};
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// 截取出csrftoken
let getCookie = function (cookie) {
  let reg = /csrftoken=([\w]+)[;]?/g
  return reg.exec(cookie)[1]
}
// 统一给post添加头请求
axios.defaults.withCredentials = true
axios.interceptors.request.use(
  function (config) {
    // 在post请求前统一添加X-CSRFToken的header信息
    let token = document.cookie;
    if (token && config.method == 'post') {
      config.headers['X-CSRFToken'] = getCookie(token);
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
)

const app = createApp(App)
app.use(ElementPlus, {
  locale: zhCn,
})
app.use(router)
app.mount('#app');