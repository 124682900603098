<!--  frontend/src/components/ArticleList.vue  -->
<template>
  <div v-loading.fullscreen.lock="loading">
    <div style="margin-bottom: 25px;margin-top: 30px;">
      <el-row v-show="lg_enough">
        <el-col
          :span="24"
          v-if="bread_enabled"
        >
          <el-breadcrumb>
            <!-- Home I write dead, other traversal out -->
            <el-breadcrumb-item>
              <el-link
                v-on:click="getListByBread(0)"
                class="el-link-bread"
              >全部</el-link>
            </el-breadcrumb-item>
            <!-- Because the route is returned in the background, the title is obtained according to the background format -->
            <el-breadcrumb-item
              v-for="(item, id) in bread_list"
              :key="id"
            >
              <el-link
                class="el-link-bread"
                v-on:click="getListByBread(item.id)"
              >{{ item.label }}</el-link>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <!-- <el-col
          :span="24"
          style="text-align: right"
        >
          <el-tag
            style="padding: 4px; margin-right: 4px; cursor: pointer"
            v-for="tag in tags"
            :key="tag.name"
            class="mx-1"
            :closable="tag.close"
            v-on:close="closeTag(tag)"
            v-on:click="clearTag(tag)"
            :type="tag.type"
          >{{ tag.name }}</el-tag>
        </el-col> -->
      </el-row>
      <el-divider
        v-show="lg_enough"
        style="margin-bottom: 8px; margin-top: 4px; width: 99%"
        v-if="bread_enabled"
      />
    </div>
    <el-row
      v-for="article in info.results"
      v-bind:key="article.url"
      style="margin-bottom: 12px; "
      :gutter="5"
    >
      <el-col
        :xs="1"
        :sm="1"
        :md="4"
        :lg="3"
        :xl="2"
        style="margin-top: 10px;"
        v-show="lg_enough"
      >
        <el-row justify="end">
          <div class="date-div">
            <span class="mmdd">{{
                formatted_time(article.publish_date).slice(5)
              }}</span>
            <span class="yyyy">{{
                formatted_time(article.publish_date).slice(0,4)
              }}</span>
          </div>
        </el-row>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="18"
        :lg="20"
        :xl="20"
      >
        <div style="font-size:20px;margin-top: 0px">
          <svg
            v-if="article.locked < 2"
            class="lock"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            data-v-78e17ca8=""
          >
            <path
              fill="currentColor"
              d="M224 448a32 32 0 0 0-32 32v384a32 32 0 0 0 32 32h576a32 32 0 0 0 32-32V480a32 32 0 0 0-32-32H224zm0-64h576a96 96 0 0 1 96 96v384a96 96 0 0 1-96 96H224a96 96 0 0 1-96-96V480a96 96 0 0 1 96-96z"
            ></path>
            <path
              fill="currentColor"
              d="M512 544a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V576a32 32 0 0 1 32-32zm192-160v-64a192 192 0 1 0-384 0v64h384zM512 64a256 256 0 0 1 256 256v128H256V320A256 256 0 0 1 512 64z"
            ></path>
          </svg>
          <a
            class="el-link-list"
            @click="redirectDetail(article)"
            style="margin-bottom: 10px; margin-top: 0px;text-decoration: none;cursor: pointer;"
          >{{ article.title }}</a>
          <svg
            v-if="article.comment_exist"
            class="comments"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="currentColor"
              d="M273.536 736H800a64 64 0 0 0 64-64V256a64 64 0 0 0-64-64H224a64 64 0 0 0-64 64v570.88L273.536 736zM296 800 147.968 918.4A32 32 0 0 1 96 893.44V256a128 128 0 0 1 128-128h576a128 128 0 0 1 128 128v416a128 128 0 0 1-128 128H296z"
            ></path>
            <path
              fill="currentColor"
              d="M512 499.2a51.2 51.2 0 1 1 0-102.4 51.2 51.2 0 0 1 0 102.4zm192 0a51.2 51.2 0 1 1 0-102.4 51.2 51.2 0 0 1 0 102.4zm-384 0a51.2 51.2 0 1 1 0-102.4 51.2 51.2 0 0 1 0 102.4z"
            ></path>
          </svg>
        </div>
        <el-row>
          <el-col style="font-size: small; color: #666666">
            {{article.summary}}
          </el-col>
        </el-row>
        <el-row
          justify="end"
          :gutter="5"
        >
          <!-- <el-col :span="18">
            <el-tag
              style="margin-right: 4px; cursor: pointer"
              v-for="tag in article.tag"
              :key="tag.name"
              effect="light"
              v-on:click="getListByTag(tag)"
            >{{ tag.tag }}</el-tag>
          </el-col> -->
          <el-col :span="6">
            <el-button
              @click="redirectDetail(article)"
              size="small"
              type="warning"
              :icon="Reading"
              round
              title="阅读全文"
              style="margin-left: 10px; margin-right: 10px;margin-top: -3px;margin-bottom: 3px"
            >
              阅读全文
            </el-button>
            <el-button
              @click="openAudio(article)"
              v-if="article.audio != ''"
              size="small"
              type="success"
              title="会议录音"
              :icon="Headset"
              round
              style="margin-left: 10px; margin-top: -3px"
            >
              会议录音
            </el-button>
          </el-col>
        </el-row>
        <el-divider
          style="margin:4px;"
          border-style="dashed"
        />
        <span
          style="font-size: small; color: #666666"
          v-html="article.highlight"
        >
        </span>
      </el-col>
    </el-row>
    <div
      id="pagination"
      class="example-pagination-block"
    >
      <el-pagination
        background
        layout="prev, pager, next"
        :page-count="Math.ceil(info.count/5)"
        :currentPage="currentPage"
        @update:current-page="handleCurrentChange"
      />
    </div>
  </div>
  <el-dialog
    title="登录FOST研究报告数据库"
    width="480px"
    v-model="loginVisible"
  >
    <div>
      <el-tabs
        v-model="activeName"
        @tab-change="onLoginChange"
      >
        <el-tab-pane
          label="账号密码"
          name="account"
        ><el-row>
            <el-col :span="24">
              <el-form
                :model="form"
                label-width="60px"
                style="max-width: 300px"
              >
                <el-form-item label="用户名">
                  <el-input
                    v-model="form.username"
                    @keyup.enter="onLogin"
                  />
                </el-form-item>
                <el-form-item label="密码">
                  <el-input
                    v-model="form.pwd"
                    type="password"
                    placeholder="请输入密码"
                    show-password
                    @keyup.enter="onLogin"
                  />
                </el-form-item>
                <el-form-item label="验证码">
                  <el-row :gutter="10">
                    <el-col
                      :span="12"
                      @click="captcha_get"
                    >
                      <img
                        :src="imgurl"
                        alt=""
                      >
                    </el-col>
                    <el-col :span="12"><el-input
                        v-model="form.captcha"
                        type="text"
                        placeholder="点击图片刷新"
                      />
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item>
                  <div style="text-align: right; ">
                    <el-button
                      type="primary"
                      @click="onLogin"
                    >登录</el-button>
                  </div>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row></el-tab-pane>
        <el-tab-pane
          label="手机号码"
          name="phone"
        ><el-row>
            <el-col :span="24">
              <el-form
                :model="form"
                style="max-width: 300px"
              >
                <el-form-item label="手机号">
                  <el-input
                    v-model="form.username"
                    @keyup.enter="onLogin"
                    placeholder="请输入手机号"
                  />
                </el-form-item>
                <el-form-item label="验证码" v-if="isShowCode">
                  <el-input
                    v-model="form.pwd"
                    type="text"
                    placeholder="请输入手机验证码"
                  />
                </el-form-item>
                <el-form-item v-if="!isShowCode">
                  <el-row :gutter="10">
                    <el-col
                      :span="12"
                      @click="captcha_get"
                    >
                      <img
                        :src="imgurl"
                        alt=""
                      >
                    </el-col>
                    <el-col :span="8"><el-input
                        v-model="form.captcha"
                        type="text"
                        placeholder="点击图片刷新"
                      />
                    </el-col>
                    <el-col :span="4"><el-button
                        type="primary"
                        @click="captcha_verify()"
                      >验证</el-button>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item v-if="isShowCode">
                  <div style="text-align: right; ">
                    <el-button
                      type="primary"
                      @click="onLogin"
                    >登录</el-button>
                  </div>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row></el-tab-pane>
        <el-tab-pane
          label="邮箱账号"
          name="mail"
        ><el-row>
            <el-col :span="24">
              <el-form
                :model="form"
                style="max-width: 300px"
              >
                <el-form-item label="邮件地址">
                  <el-input
                    v-model="form.username"
                    @keyup.enter="onLogin"
                    placeholder="请输入邮件地址"
                  />
                </el-form-item>
                <el-form-item label="验证码" v-if="isShowCode">
                  <el-input
                    v-model="form.pwd"
                    type="text"
                    placeholder="请输入邮箱验证码"
                  />
                </el-form-item>
                <el-form-item v-if="!isShowCode">
                  <el-row :gutter="10">
                    <el-col
                      :span="12"
                      @click="captcha_get"
                    >
                      <img
                        :src="imgurl"
                        alt=""
                      >
                    </el-col>
                    <el-col :span="8"><el-input
                        v-model="form.captcha"
                        type="text"
                        placeholder="点击图片刷新"
                      />
                    </el-col>
                    <el-col :span="4"><el-button
                        type="primary"
                        @click="captcha_verify()"
                      >验证</el-button>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item v-if="isShowCode">
                  <div style="text-align: right; ">
                    <el-button
                      type="primary"
                      @click="onLogin"
                    >登录</el-button>
                  </div>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row></el-tab-pane>
      </el-tabs>

      <el-row>
        <el-col :span="24">
          <el-divider />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <p>
            没有开通账号，或账号登录有问题，请联系FOST客服。
          </p>
          <p>
            电话：010-69519882，85835562，(工作日8:00-17:00)
          </p>
          <p>
            电子邮箱：info@fostecon.cn
          </p>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
  <el-dialog
    title="请输入验证码"
    width="320px"
    v-model="captchaVisible"
  >
    <el-row :gutter="10">
      <el-col
        :span="12"
        @click="captcha_get"
      >
        <img
          :src="imgurl"
          alt=""
        >
      </el-col>
      <el-col :span="12"><el-input
          v-model="form.captcha"
          type="text"
          placeholder=""
        />
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col
        :span="24"
        style="text-align:right"
      >
        <el-button
          type="primary"
          @click="captcha_verify()"
        >验证</el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>
<script>
import { ref, reactive, onUnmounted, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import qs from "qs";
import formattedTime from "@/composables/formattedTime.js";
import { emitter } from "@/libs/bus.ts";
import { Headset, Reading } from "@element-plus/icons-vue";
import { ElMessage, ElNotification } from "element-plus";
const lg_enough = ref(true);
const loginVisible = ref(false);
const captchaVisible = ref(false);
const loginType = ref("0");
const codeSended = ref(false);
const activeName = ref("account");
const isShowCode = ref(false)
const form = reactive({
  username: "",
  pwd: "",
  captcha: "",
  uuid: "",
  type: "account",
});
export default {
  mounted() {
    lg_enough.value = document.body.clientWidth >= 992;
  },
  setup() {
    let info = ref("");
    let route = useRoute();
    let formatted_time = formattedTime;
    const size = 5;
    const currentPage = ref(0);
    let category_id = 0;
    let search_text = "";
    let report_type = "0";
    let router = useRouter();
    const bread_list = ref([]);
    const tags = ref([]);
    const date_range = ref([]);
    const loading = ref(false);
    const bread_enabled = ref(true);
    const summary = ref("");
    let search_type = "0";
    const imgurl = ref("");
    let redirect_id = 0;
    // 手机号获取验证码前先输入图形验证码
    const onGetMSg = () => {
      var phone_reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      var mail_reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (form.type == "phone" && !phone_reg.test(form.username)) {
        ElMessage.error("请输入正确的手机号");
      } else if (form.type == "mail" && !mail_reg.test(form.username)) {
        ElMessage.error("请输入正确的邮箱");
      } else {
        captcha_get();
        form.captcha = "";
        captchaVisible.value = true;
      }
    };
    // 切换登录类型
    const onLoginChange = () => {
      form.username = "";
      form.pwd = "";
      form.captcha = "";
      form.type = activeName.value;
      captcha_get();
    };
    const captcha_verify = async () => {
      let url =
        "/api/captcha_verify?uuid=" +
        form.uuid +
        "&captcha=" +
        form.captcha +
        "&uname=" +
        form.username +
        "&utype=" +
        form.type;
      let response = await axios.get(url);
      if (response.data.result) {
        if (form.type == "phone") {
          ElMessage.success("短信验证码已发送");
        }
        if (form.type == "mail") {
          ElMessage.success("验证码已发送至您的邮箱");
        }
        codeSended.value = true;
        captchaVisible.value = false;
        isShowCode.value = true;
      } else {
        ElMessage.error(response.data.msg);
      }
    };
    // 获取图形验证码
    const captcha_get = async () => {
      let s = [];
      let hexDigits = "0123456789abcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4";
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
      s[8] = s[13] = s[18] = s[23] = "-";

      form.uuid = s.join("");
      let url = "/api/captcha_get?uuid=" + form.uuid;
      let response = await axios.get(url);
      imgurl.value = response.data.base64;
    };
    // 获取token
    const getToken = async () => {
      let url = "/api/token/";
      let response = await axios.get(url);
      return response.data.token;
    };
    // 登录
    const onLogin = async () => {
      if (form.username === "" || form.pwd === "") {
        if (form.type == "account") {
          ElMessage.error("请输入用户名和密码");
          return;
        }
        if (form.type == "phone") {
          ElMessage.error("请输入手机号及验证码");
          return;
        }
        if (form.type == "mail") {
          ElMessage.error("请输入邮箱及验证码");
          return;
        }
      }
      if (form.type == "account" && form.captcha === "") {
        ElMessage.error("请输入验证码");
        return;
      }
      let url = "/api/login";
      let csrftoken = getToken();
      let headers = { "X-CSRFToken": csrftoken };
      let response = await axios.post(url, qs.stringify(form), {
        headers: headers,
      });
      let result = response.data.result;
      codeSended.value = false;
      isShowCode.value = false;
      if (result) {
        localStorage.setItem("Authorization", true);
        localStorage.setItem("company", response.data.msg);
        localStorage.setItem("info", response.data.info);
        if (redirect_id != 0) {
          let route = router.resolve({ path: "/article/" + redirect_id });
          window.open(route.href, "_blank");
        }
        location.reload();
      } else {
        ElMessage.error(response.data.msg);
      }
    };
    // 添加清除tag
    const addClearTag = () => {
      let clear = { name: "清除所有条件", type: "info", id: -1 };
      let clear_exist = false;
      for (let i = 0; i < tags.value.length; i++) {
        if (tags.value[i].id == clear.id) {
          clear_exist = true;
          break;
        }
      }
      if (!clear_exist) {
        tags.value.push(clear);
      }
    };
    // 清除tag
    const clearTag = (tag) => {
      //console.log("clearTag");
      if (tag.id == -1) {
        tags.value = [];
      }
      date_range.value = [];
      search_text = "";
      emitter.emit("date-clear");
      emitter.emit("search-clear");
      getList();
    };
    // 关闭tag
    const closeTag = (tag) => {
      //console.log("closeTag");
      for (let i = 0; i < tags.value.length; i++) {
        if (tags.value[i].id == tag.id) {
          if (tag.id == 0) {
            search_text = "";
            emitter.emit("search-clear");
          }
          if (tag.id == -2) {
            date_range.value = [];
            emitter.emit("date-clear");
          }
          tags.value.splice(i, 1);
          break;
        }
      }
      if (tags.value.length == 1) {
        tags.value = [];
      }
      getList();
    };
    // 点击列表tag
    const getListByTag = (tag) => {
      //console.log("getListByTag");
      let t = { name: tag.tag, type: "success", id: tag.id, close: true };
      let tag_exist = false;

      for (let i = 0; i < tags.value.length; i++) {
        if (tags.value[i].id == t.id) {
          tag_exist = true;
          break;
        }
      }
      addClearTag();
      if (!tag_exist) {
        tags.value.unshift(t);
        getList();
      }
    };
    // 搜索
    const getListBySearch = (msg) => {
      console.log("getListBySearch");
      // emitter.emit("switch", { text: "0", disabled: "1" });
      search_text = msg.text;
      date_range.value = msg.date;
      search_type = msg.t;
      currentPage.value = 1;
      bread_list.value = [];
      report_type = 0;
      for (let i = 0; i < tags.value.length; i++) {
        if (tags.value[i].id == 0) {
          if (tags.value[i].name == search_text) {
            return;
          } else {
            tags.value.splice(i, 1);
          }
          break;
        }
      }
      if (
        date_range.value != [] &&
        date_range.value[0] != undefined &&
        date_range.value[1] != undefined
      ) {
        let t = {
          name: date_range.value[0] + "至" + date_range.value[1],
          type: "warning",
          id: -2,
          close: true,
        };
        for (let i = 0; i < tags.value.length; i++) {
          if (tags.value[i].id == -2) {
            tags.value.splice(i, 1);
            break;
          }
        }
        tags.value.unshift(t);
      }
      addClearTag();
      tags.value.unshift({ name: search_text, type: "", id: 0, close: true });
      getList();
    };
    // 切换面包屑,重置list
    const getListByBread = (val) => {
      //console.log("getListByBread");
      if (val == 0) {
        bread_list.value = [];
        category_id = 0;
        getList();
      }
      emitter.emit("bread-click", val);
    };
    // 切换分类,重置list
    const getListByCategory = (msg) => {
      // console.log("getListByCategory");
      currentPage.value = 1;
      category_id = msg.click_id;
      bread_list.value = msg.breads;
      getList();
    };
    // 切换报告类别,重置list
    const getListByMenu = (msg) => {
      // console.log("getListByMenu");
      // console.log(msg);
      info.value = "";
      search_text = "";
      category_id = 0;
      currentPage.value = 1;
      bread_list.value = [];
      tags.value = [];
      date_range.value = [];
      report_type = msg.text;
      if (report_type == "0") {
        bread_enabled.value = true;
      } else {
        bread_enabled.value = false;
      }
      getList();
      // if (msg.disabled == "0") {
      //   var pagination = document.getElementById("pagination");
      //   if (pagination.firstChild.length == 0) {
      //     getList();
      //   }
      // }
    };

    // 列表数据
    const getList = async (val = 1) => {
      // console.log("getList");
      loading.value = true;
      var url = "/api/article";
      currentPage.value = val;
      var params = new URLSearchParams();
      params.appendIfExists("page", currentPage.value);
      params.appendIfExists("search", route.query.search);
      params.appendIfExists("category", route.query.category);
      params.appendIfExists("report", report_type);
      // 加入日期
      if (date_range.value !== []) {
        params.appendIfExists("from", date_range.value[0]);
        params.appendIfExists("to", date_range.value[1]);
      }
      // 加入分类
      if (category_id !== 0) {
        params.appendIfExists("category", category_id);
      }
      // 加入搜索
      if (search_text !== "") {
        params.appendIfExists("search", search_text);
        params.appendIfExists("search_type", search_type);
      }
      // 加入标签
      if (tags.value.length > 0) {
        for (let i = 0; i < tags.value.length; i++) {
          if (
            tags.value[i].id != 0 &&
            tags.value[i].id != -1 &&
            tags.value[i].id != -2
          ) {
            params.appendIfExists("tag", tags.value[i].id);
          }
        }
      }
      const paramsString = params.toString();
      if (paramsString.charAt(0) !== "") {
        url += "/?" + paramsString;
      }
      try {
        var response = await axios.get(url);
        if (response.data.count == 0) {
          info.value = "";
        } else {
          info.value = response.data;
        }

        loading.value = false;
      } catch (err) {
        loading.value = false;
        if (err.toJSON().status == 403) {
          router.push({ path: "/login" });
          return;
        }
        let err_url = err.response.request.responseURL;
        if (err_url.indexOf("login") !== -1) {
          router.push({
            name: "LoginVue",
            params: { msg: "当前登录人数超过限制,请重新登录" },
          });
        }
      }
    };
    // 第一次获取数据
    getList(1);
    // 翻页局部刷新
    const handleCurrentChange = async (val) => {
      // console.log("handleCurrentChange");
      // console.log(val);
      getList(val);
    };
    const handlePageSize = () => {
      getList(1);
    };
    const redirectDetail = (article) => {
      if (article.locked == 1) {
        ElMessage({
          showClose: true,
          message: "您还没有购买此项报告服务,请联系客服。",
          type: "error",
        });
      } else if (article.locked == 0) {
        redirect_id = article.id;
        loginVisible.value = true;
        captcha_get();
      } else {
        let route = router.resolve({ path: "/article/" + article.id });
        window.open(route.href, "_blank");
      }
    };
    const getData = async (article) => {
      ElNotification.closeAll();
      let url = "";
      var msg =
        '<audio id ="audio_player" controls autoplay  controlsList="nodownload" style="margin-left:-30px" preload="auto"><source src="' +
        url +
        '" type="audio/mpeg" /></audio><span id="audio_loading" style="margin-left:60px">会议音频加载中</span>';
      ElNotification({
        title: "[会议录音]" + article.title,
        dangerouslyUseHTMLString: true,
        message: msg,
        type: "info",
        duration: 0,
      });
      try {
        let response = await axios.get("/api/record/" + article.id, {
          responseType: "blob",
        });
        const binaryData = [];
        binaryData.push(response.data);
        url = window.URL.createObjectURL(
          new Blob(binaryData, { type: "audio/mpeg" })
        );
        document.getElementById("audio_player").src = url;
        document.getElementById("audio_loading").hidden = true;
      } catch (err) {
        if (err.toJSON().status == 403) {
          router.push({ path: "/login" });
          return;
        }
      }
    };
    const openAudio = (article) => {
      if (article.locked == 1) {
        ElMessage({
          showClose: true,
          message: "您还没有购买此项报告服务,请联系客服。",
          type: "error",
        });
      } else if (article.locked == 0) {
        loginVisible.value = true;
        captcha_get();
      } else {
        getData(article);
      }
    };
    onMounted(() => {
      // 侦听事件
      emitter.on("tree-click", getListByCategory);
      emitter.on("search", getListBySearch);
      emitter.on("switch", getListByMenu);
    });
    onUnmounted(() => {
      emitter.off("tree-click", getListByCategory);
      emitter.off("search", getListBySearch);
      emitter.off("switch", getListByMenu);
      document.removeEventListener("current-change", handleCurrentChange);
    });
    return {
      info,
      formatted_time,
      size,
      currentPage,
      bread_list,
      tags,
      handleCurrentChange,
      handlePageSize,
      getListByBread,
      getListByTag,
      closeTag,
      clearTag,
      loading,
      bread_enabled,
      Headset,
      Reading,
      summary,
      redirectDetail,
      openAudio,
      lg_enough,
      loginVisible,
      form,
      onLogin,
      captcha_get,
      imgurl,
      loginType,
      onLoginChange,
      onGetMSg,
      codeSended,
      captchaVisible,
      captcha_verify,
      activeName,
      isShowCode
    };
  },
};
</script>

<!-- "scoped" 使样式仅在当前组件生效 -->
<style scoped>
.el-row {
  margin-bottom: 8px;
}
.el-link-bread {
  font-size: 16px;
  color: rgb(51, 51, 51);
  font-family: "微软雅黑", "宋体";
}
.el-link-list {
  font-size: 16px;
  color: black;
}
.el-pagination {
  font-family: "微软雅黑", "宋体";
}
.date {
  font-size: 12px;
  font-family: "微软雅黑", "宋体";
  color: #aaaaaa;
}
.el-link .el-icon--right.el-icon {
  vertical-align: text-bottom;
}
.tag {
  padding: 2px 5px 2px 5px;
  margin: 5px 5px 5px 0;
  font-family: Georgia, Arial, sans-serif;
  font-size: small;
  background-color: #4e4e4e;
  color: whitesmoke;
  border-radius: 5px;
}
.date-div {
  width: 60px;
  height: 60px;
  float: left;
  text-align: left;
  overflow: hidden;
  border-radius: 15px;
  border: 0px solid;
  background-color: Aqua;
  padding: 5px;
  margin-right: 8px;
  background-color: #337ecc;
  margin-top: 0px;
}
.yyyy {
  display: block;
  font-size: 23px;
  margin-top: 2px;
  color: #fff; /*#494949*/
  /*padding-right: 15px;*/
  height: 20px;
  line-height: 20px;
  overflow: hidden;
  width: 100%;
  font-family: Arial CE;
  text-align: center;
}
.mmdd {
  margin-top: 5px;
  display: block;
  font-size: 19px;
  color: #fff; /*#9e9e9e*/
  height: 25px;
  line-height: 25px;
  overflow: hidden;
  width: 100%;
  font-family: Arial CE;
  text-align: center;
}
.lock {
  width: 1em;
  height: 1em;
  margin-right: 2px;
  margin-left: 2px;
  color: red;
}
.comments {
  width: 1em;
  height: 1em;
  margin-right: 2px;
  margin-left: 2px;
  margin-bottom: 4px;
  color: red;
}
</style>