<!--  frontend/src/components/TreeMenu.vue  -->
<template>
  <div
    id="TreeMenu"
    style="margin-top:15px"
  >
    <el-scrollbar height="630px">
      <el-tree
        :data="data"
        ref="menutree"
        node-key="id"
        accordion
        :indent=10
        @node-click="handleNodeClick"
        style="--el-font-size-base: 16px"
      />
    </el-scrollbar>
  </div>
</template>
<script>
import { ref } from "vue";
import { emitter } from "@/libs/bus.ts";
import { useRouter } from "vue-router";
import axios from "axios";
export default {
  setup() {
    let router = useRouter();
    const data = ref([]);
    const menutree = ref(null);
    let breads = [];
    const getTreeByMenu = (msg) => {
      if (msg.text == "0") {
        getData();
      }
    };
    const handleNodeClick = (target) => {
      breads = [];
      getTreeNode(menutree.value.getNode(target.id));
      emitter.emit("tree-click", { click_id: target.id, breads: breads });
    };
    const getTreeNode = (node) => {
      if (node && node.label) {
        var regex = /\((.+?)\)/g;
        breads.unshift({
          label: node.label.replace(regex, ""),
          id: node.data.id,
        }); //在数组头部添加元素
        getTreeNode(node.parent); //递归
      }
    };
    const setNodeSelect = (val) => {
      breads = [];
      if (val > 0) {
        var node = menutree.value.getNode(val);
        node.expanded = false;
        getTreeNode(node);
        emitter.emit("tree-click", { click_id: val, breads: breads });
      } else {
        getData();
      }
    };
    // 侦听切换面包屑事件
    emitter.on("bread-click", setNodeSelect);
    emitter.on("switch", getTreeByMenu);

    const getData = async () => {
      let url = "api/category/0";
      try {
        const response = await axios.get(url);
        data.value = response.data.tree;
      } catch (err) {
        if (err.toJSON().status == 403) {
          router.push({ path: "/login" });
          return;
        }
      }
    };
    getData();
    return {
      data,
      breads,
      menutree,
      handleNodeClick,
      setNodeSelect,
    };
  },
};
</script>
<style scoped>
.el-tree {
  color: black;
  font-family: "微软雅黑", "宋体";
}
</style>
